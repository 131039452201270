import React, {lazy, Suspense} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Loader from "react-loader-spinner";
import { styles } from '../css/globalStyles';

const TimeReg = lazy(() => import('./CoreComponents/TimeReg'));
const TimeRegExport = lazy(() => import('./TimeRegExport/TimeRegExport'));
const Budget = lazy(() => import('./Budget/Budget'));
const Approvals = lazy(() => import('./Approvals/Approvals'));
const People = lazy(() => import('./People/People'));
const Admin = lazy(() => import('./Admin/Admin'));
const Reports = lazy(() => import('./Reports/Reports'));
const AdminEdit = lazy(() => import('./Admin/AdminEdit'));
const Activity = lazy(() => import('./Activity/Activity'));
const Invoices = lazy(() => import('./Invoices/Invoices'));
const ForecastDetails = lazy(() => import('./ForecastDetails/ForecastDetails'));
const Forecast = lazy(() => import('./Forecast/Forecast'));
const ForecastOverview = lazy(() => import('./ForecastOverview/ForecastOverview'));

export default function App() {
    return (
        <Router basename={'frontend'}>
            <div>
                <Switch>
                    <Suspense fallback={<Loader
                        type="Puff"
                        color="#00BFFF"
                        height={200}
                        width={200}
                        style={{ ...styles.loader, background: "transparent"}}
                    />}>
                    <Route path="/timereg">
                        <TimeReg />
                    </Route>
                    <Route path="/approvals">
                        <Approvals />
                    </Route>
                    <Route path="/reports">
                        <Reports />
                    </Route>
                    <Route path="/activity">
                        <Activity />
                    </Route>
                    <Route path="/budget">
                        <Budget />
                    </Route>
                    <Route path="/invoices">
                        <Invoices />
                    </Route>
                    <Route path="/people">
                        <People />
                    </Route>
                    <Route path="/admin">
                        <Admin />
                    </Route>
                    <Route path="/admin-edit">
                        <AdminEdit />
                    </Route>
                    <Route path="/forecast-details">
                        <ForecastDetails />
                    </Route>
                    <Route path="/forecast">
                        <Forecast />
                    </Route>
                    <Route path="/forecast-overview">
                        <ForecastOverview />
                    </Route>
                    <Route path="/timereg-export">
                        <TimeRegExport />
                    </Route>
                    <Route path="/" exact>
                        <TimeReg />
                    </Route>
                    </Suspense>
                </Switch>
            </div>
        </Router>
    );
}
