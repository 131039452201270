import { makeStyles, Theme } from '@material-ui/core/styles';

let darkGrayText = "#4B4B4B";

export const stylesGenerator = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  toolbar: {
    backgroundColor: '#ffffff',
    color: '#787878',
    paddingLeft: 15,
    "& $img": {
      height: 40,
      marginRight: 40
    }
  },
  menuicon: {
    color: darkGrayText,
    backgroundColor: 'none!important',
    "&:hover": {
      color: darkGrayText
    },
  },
  logo: {
    height: 40,
    marginRight: 40
  },
  list: {
    width: 250,
    zIndex: 9999,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
    color: darkGrayText,
    fontFamily: "Nunito Sans"
  },
  topbarmenu: {
    textAlign: 'right',
    color: darkGrayText,
    fontFamily: "Nunito Sans",
    marginRight: 40
  },
  button: {
    minWidth: "15px",
    padding: "5px",
  },
  link: {
    color: "#5b5b5b",
    textDecoration: 'none',
    width: '100%'
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  floatRight: {
    float: 'right'
  },
  floatLeft: {
    float: 'left'
  },
  table: {
    backgroundColor: 'rgb(231, 231, 232)',
    whiteSpace: 'nowrap',
    wordWrap: "normal",
    wordBreak: "normal",
    "& $tbody": {
      "& $tr": {
        "& $th": {
          borderColor: '#e7e7e8',
          borderRadius: 5,
          color: 'white',
          padding: 3,
          borderWidth: 2,
          width: 25,
          fontSize: 'inherit',
          fontFamily: "Nunito Sans Bold"
        },
        "& $td": {
          backgroundColor: '#ffffff',
          borderColor: '#e7e7e8',
          borderRadius: 5,
          borderWidth: 2,
          padding: 3,
          width: 25,
          color: '#949494',
          fontSize: 'inherit',
          fontFamily: "Nunito Sans Bold",
          "&:hover": {
            borderColor: "#bbc3dc"
          },
        }
      }
    },
    "& $thead": {
      "& $tr": {
        "& $th": {
          borderColor: '#e7e7e8',
          borderRadius: 5,
          color: 'white',
          backgroundColor: '#A7A7A7',
          padding: 3,
          borderWidth: 2,
          width: 25,
          fontSize: 'inherit',
          position: 'sticky',
          fontFamily: "Nunito Sans Bold",
          "& $a": {
            color: 'white'
          }
        },
        "& $td": {
          backgroundColor: '#ffffff',
          borderColor: '#e7e7e8',
          borderRadius: 5,
          borderWidth: 2,
          padding: 3,
          width: 25,
          fontSize: 'inherit',
          fontFamily: "Nunito Sans Bold"
        }
      }
    }
  },
  tableContainer: {
    background: 'none',
    boxShadow: 'none',
    overflowX: 'auto',
    "& $div": {
      background: 'none',
      boxShadow: 'none',
      display: 'contents'
    }
  },
  tableContainerSmall: {
    background: 'none',
    boxShadow: 'none',
    "& $div": {
      background: 'none',
      boxShadow: 'none',
      maxHeight: 400
    }
  },
  border: {
    wordWrap: "normal",
    wordBreak: "normal",
    textAlign: 'center',
    borderTopWidth: 'thin',
    borderBottomWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderColor: 'black',
    borderStyle: 'solid',
    backgroundColor: '#C0C0C0'
  },
  limitedTableCell: {
    textAlign: "left"
  },
  tableCell: {
    wordWrap: "normal",
    wordBreak: "normal",
    textAlign: 'center',
    borderTopWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderColor: 'black',
    borderStyle: 'solid'
  },
  tableCellLeft: {
    textAlign: 'left',
    wordWrap: "normal",
    wordBreak: "normal",
    borderTopWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderColor: 'black',
    borderStyle: 'solid'
  },
  tableCellLeftBlue: {
    textAlign: 'left',
    wordWrap: "normal",
    wordBreak: "normal",
    borderTopWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderColor: 'black',
    borderStyle: 'solid',
    backgroundColor: 'lightblue'
  },
  tableCellLeftYellow: {
    textAlign: 'left',
    wordWrap: "normal",
    wordBreak: "normal",
    borderTopWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderColor: 'black',
    borderStyle: 'solid',
    backgroundColor: '#ffce2d!important'
  },
  tableCellLeftGreen: {
    textAlign: 'left',
    wordWrap: "normal",
    wordBreak: "normal",
    borderTopWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderColor: 'black',
    borderStyle: 'solid',
    backgroundColor: '#a2e698!important'
  },
  tableCellSelected: {
    wordWrap: "normal",
    wordBreak: "normal",
    textAlign: 'center',
    borderTopWidth: 'thin',
    borderBottomWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderStyle: 'solid',
    borderColor: '#e7e7e8!important',
    backgroundColor: '#3f97d8!important',
    color: '#FFFFFF!important',
    outlineColor: '#e7e7e8!important'

  },
  tableHeaderSelected: {
    wordWrap: "normal",
    wordBreak: "normal",
    textAlign: 'left',
    borderTopWidth: 'thin',
    borderBottomWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderStyle: 'solid',
    borderColor: '#e7e7e8',
    borderRadius: 5,
    backgroundColor: '#3f97d8!important',
    paddingLeft: '10px!important',
    top: 0,
    left: 0,
    zIndex: 3,
    position: 'sticky',
    borderCollapse: 'collapse'
  },
  tableHeader: {
    wordWrap: "normal",
    wordBreak: "normal",
    textAlign: 'center',
    borderTopWidth: 'thin',
    borderBottomWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderStyle: 'solid',
    borderColor: 'black',
    backgroundColor: '#9daacd',
    position: 'relative',
    zIndex: 1,
    cursor: 'pointer'
  },
  tableHeaderFirst: {
    wordWrap: "normal",
    wordBreak: "normal",
    textAlign: 'left',
    borderTopWidth: 'thin',
    borderBottomWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderStyle: 'solid',
    borderColor: '#e7e7e8',
    borderRadius: 5,
    zIndex: 10,
    backgroundColor: '#6a7db2!important',
    paddingLeft: '10px!important',
    color: 'white!important',
    cursor: 'pointer',
    "& $a": {
      color: 'white'
    },
  },
  tableHeaderFirstGray: {
    wordWrap: "normal",
    wordBreak: "normal",
    textAlign: 'left',
    borderTopWidth: 'thin',
    borderBottomWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderStyle: 'solid',
    borderColor: '#e7e7e8',
    borderRadius: 5,
    backgroundColor: '#A7A7A7!important',
    paddingLeft: '10px!important',
    zIndex: 11,
    position: 'sticky',
    padding: '3px!important',
    borderCollapse: 'collapse',
    cursor: 'pointer'
  },
  tableHeaderGray: {
    wordWrap: "normal",
    wordBreak: "normal",
    textAlign: 'left',
    borderTopWidth: 'thin',
    borderBottomWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderStyle: 'solid',
    borderColor: '#e7e7e8',
    borderRadius: 5,
    backgroundColor: '#A7A7A7!important',
    paddingLeft: '10px!important',
    zIndex: 9,
    position: 'sticky',
    padding: '3px!important',
    borderCollapse: 'collapse',
    cursor: 'pointer'
  },
  tableHeaderSticky: {
    wordWrap: "normal",
    wordBreak: "normal",
    textAlign: 'center',
    borderTopWidth: 'thin',
    borderBottomWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderStyle: 'solid',
    borderColor: 'black',
    backgroundColor: '#9daacd',
    top: 0,
    left: 0,
    zIndex: 6,
    position: 'sticky',
    borderCollapse: 'collapse',
    cursor:'pointer'
  },
  tableHeaderLeft: {
    wordWrap: "normal",
    wordBreak: "normal",
    textAlign: 'left',
    borderTopWidth: 'thin',
    borderBottomWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderStyle: 'solid',
    borderColor: 'black',
    backgroundColor: '#9daacd'
  },
  tableHeaderForBudget: {
    wordWrap: "normal",
    wordBreak: "normal",
    textAlign: 'center',
    borderTopWidth: 'thin',
    borderBottomWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderStyle: 'solid',
    borderColor: 'black',
    position: 'relative',
    zIndex: 1,
    width: '70px!important',
    backgroundColor: '#6a7db2'
  },
  tableHeaderForWeekendsAndVacationDays: {
    wordWrap: "normal",
    wordBreak: "normal",
    textAlign: 'center',
    borderTopWidth: 'thin',
    borderBottomWidth: 'thin',
    borderLeftWidth: 'thin',
    borderRightWidth: 'thin',
    borderStyle: 'solid',
    borderColor: 'black',
    position: 'relative',
    zIndex: 1,
    backgroundColor: '#bbc3dc!important',
    color: 'white!important',
    "&:hover": {
      borderColor: "white!important"
    },
  },
  tableHeaderFoDaysInFuture: {
    wordBreak: "normal",
    textAlign: 'right',
    borderStyle: 'solid',
    borderColor: 'black',
    backgroundColor: '#eae9e9',
    position: 'relative'
  },
  fixLastRow: {
    paddingRight: 5,
    width: '27px!important',
    display: 'block'
  },
  padding10: {
    padding: 10
  },
  tableRow: {
    height: "30px",
    padding: "0px",
    "& $th": {
      borderColor: '#e7e7e8',
      borderRadius: 5
    },
    "& $tr": {
      borderColor: '#e7e7e8',
      borderRadius: 5
    },
  },
  padding7: {
    padding: 7
  },
  bgLightblue: {
    backgroundColor: 'lightblue'
  },
  hoverRow: {
    '&:hover': {
      backgroundColor: 'lightblue',
      "& $tableCellLeftGreen": {
        backgroundColor: "lightblue"
      },
      "& $tableCellLeftYellow": {
        backgroundColor: "lightblue"
      }
    }
  },
  monthButtonsContainer: {
    whiteSpace: 'nowrap',
    padding: 10,
    textAlign: 'right',
    marginTop: 30
  },
  yearButtonsContainer: {
    whiteSpace: 'nowrap',
    padding: 10,
    textAlign: 'right',
    marginTop: 30
  },
  monthButtonsContainerMobile: {
    whiteSpace: 'nowrap',
    padding: 10,
    textAlign: 'right',
    marginTop: 30,
    background: 'white',
    marginLeft: 30,
    marginRight: 30,
    borderRadius: 20,
    height: 20
  },
  blueButton: {
    backgroundColor: '#6a7db2',
    color: 'white',
    border: '2px white solid',
    borderRadius: 20,
    height: 38,
    width: 20,
    minWidth: 20,
    marginTop: -9,
    fontSize: 14,
    fontFamily: "Nunito Sans Bold",
  },
  currentMonth: {
    color: darkGrayText,
    display: 'inline-block',
    width: 'calc(100% - 80px)',
    textAlign: 'center',
    fontSize: 16,
    fontFamily: "Nunito Sans Bold"
  },
  hoveredRow: {
    height: "30px",
    padding: "0px",
    "& $th": {
      borderColor: '#e7e7e8',
      borderRadius: 5
    },
    "& $tr": {
      borderColor: '#e7e7e8',
      borderRadius: 5
    },
    '&:hover': {

      outline: "1px #3F97DB solid",
      "& $td": {
        borderTopColor: "#e7e7e8!important",
        borderBottomColor: "#e7e7e8!important",
        borderLeftColor: '#e7e7e8!important',
        borderRightColor: '#e7e7e8!important'
      }
    }
  },
  pointer: {
    cursor: 'pointer'
  }
}));

export const styles: any = {
  padding7: {
    padding: 7
  },
  editFormRow: {
    marginTop: 5
  },
  blueLabel: {
    width: 260,
    backgroundColor: '#6A7DB2',
    borderRadius: 4,
    color: 'white',
    padding: '2px 10px',
    display: 'inline-block'
  },
  blueLabelLarge: {
    width: 260,
    height: 56,
    backgroundColor: '#6A7DB2',
    borderRadius: 4,
    color: 'white',
    padding: '2px 10px',
    display: 'inline-block',
    position: 'absolute'
  },
  longTextField: {
    width: 740,
    marginLeft: 5,
    padding: 5,
    borderRadius: 4,
    border: "none"
  },
  areaTextField: {
    width: 740,
    marginLeft: 285,
    padding: 5,
    borderRadius: 4,
    border: "none",
    resize: "none",
    height: 50
  },
  selectField: {
    width: 750,
    marginLeft: 5,
    padding: 5,
    borderRadius: 4,
    border: "none"
  },
  selectFieldShort: {
    width: 300,
    marginLeft: 5,
    padding: 5,
    borderRadius: 4,
    border: "none"
  },
  yearSelectField: {
    width: 150,
    marginBottom: 10,
    padding: 5,
    borderRadius: 4,
    border: "none"
  },
  showInactive: {
    display: 'inline-block',
    height: 30,
    verticalAlign: 'bottom',
    marginLeft: 10
  },
  checkbox: {
    color: '#3F97D8',
    padding: 0,
    paddingLeft: 3
  },
  shortTextField: {
    width: 370,
    marginLeft: 5,
    padding: 5,
    borderRadius: 4,
    border: "none"
  },
  blueLink: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  whiteLink: {
    color: 'white',
    textDecoration: 'none'
  },
  link: {
    color: "#5b5b5b",
    textDecoration: 'none'
  },
  contentContainer: {
    padding: '0 60px 20px'
  },
  contentContainerMobile: {
    padding: '0 10px'
  },
  pageBackground: {
    backgroundColor: '#e7e7e8',
    minHeight: '100vh'
  },
  tableBackrogund: {
    borderColor: '#535353',
    borderRadius: 5
  },
  padding20: {
    padding: 20
  },
  floatRight: {
    float: 'right'
  },
  margin10: {
    margin: 10
  },
  boldFont: {
    fontSize: 16,
    color: darkGrayText,
    paddingBottom: 40,
    paddingTop: 20,
    display: 'inline-block',
    fontFamily: "Nunito Sans Bold"
  },
  boldFont2: {
    fontSize: 16,
    color: darkGrayText,
    paddingTop: 20,
    display: 'inline-block',
    fontFamily: "Nunito Sans Bold"
  },
  boldFont3: {
    fontSize: 16,
    color: darkGrayText,
    paddingTop: 20,
    paddingBottom: 20,
    display: 'inline-block',
    fontFamily: "Nunito Sans Bold"
  },
  boldFont4: {
    fontSize: 16,
    color: darkGrayText,
    display: 'inline-block',
    fontFamily: "Nunito Sans Bold"
  },
  monthButtonsContainer: {
    textAlign: 'right',
    marginTop: 40,
    float: 'right',
    backgroundColor: 'white',
    borderRadius: 20
  },
  viewButtonsContainer: {
    whiteSpace: 'nowrap',
    padding: 10,
    textAlign: 'right',
    position: 'absolute',
    right: 50
  },
  viewButtonsContainerMobile: {
    whiteSpace: 'nowrap',
    padding: 10,
    textAlign: 'right',
    right: 50
  },
  adminTabsContainer: {
  },
  adminTabsButton: {
    boxShadow: 'none',
    backgroundColor: '#6a7db2',
    color: 'white',
    borderRadius: 5,
    height: 30,
    fontSize: 12,
    marginRight: 5,
    width: '10.8%',
    textTransform: 'none',
    minWidth: 150,
    marginBottom: 5
  },
  adminTabsButtonSelected: {
    boxShadow: 'none',
    backgroundColor: '#3F97DB',
    color: 'white',
    borderRadius: 5,
    height: 30,
    fontSize: 12,
    marginRight: 5,
    width: '10.8%',
    textTransform: 'none',
    minWidth: 150,
    marginBottom: 5
  },
  currentMonth: {
    color: darkGrayText,
    display: 'inline-block',
    width: 160,
    textAlign: 'center',
    fontSize: 16,
    fontFamily: "Nunito Sans Bold"
  },
  navigationButton: {
    backgroundColor: '#6a7db2',
    color: 'white',
    border: '2px white solid',
    borderRadius: 20,
    height: 38,
    width: 160,
    fontSize: 16,
    fontFamily: "Nunito Sans Bold",
    textTransform: 'none'
  },
  blueButton: {
    backgroundColor: '#6a7db2',
    color: 'white',
    border: '2px white solid',
    borderRadius: 20,
    height: 38,
    width: 250,
    marginTop: 20,
    marginBotom: 20,
    marginRight: 10,
    fontSize: 14,
    fontFamily: "Nunito Sans Bold",
  },
  blueButtonPerson: {
    backgroundColor: '#6a7db2',
    color: 'white',
    border: '2px white solid',
    borderRadius: 20,
    height: 38,
    width: 200,
    marginTop: 10,
    marginBotom: 20,
    fontSize: 14,
    fontFamily: "Nunito Sans Bold",
  },
  blueButtonLarge: {
    backgroundColor: '#6a7db2',
    color: 'white',
    border: '2px white solid',
    borderRadius: 20,
    height: 38,
    marginTop: 20,
    marginBotom: 20,
    fontSize: 14,
    fontFamily: "Nunito Sans Bold",
    paddingLeft: 10,
    paddingRight: 10,
    width: 250
  },
  blueButtonLargeMobile: {
    backgroundColor: '#6a7db2',
    color: 'white',
    border: '2px white solid',
    borderRadius: 20,
    height: 38,
    marginTop: 20,
    marginBotom: 20,
    fontSize: 14,
    fontFamily: "Nunito Sans Bold",
    paddingLeft: 10,
    paddingRight: 10,
    width: 250,
    display: 'inline-block'
  },
  submitButton: {
    backgroundColor: '#6a7db2',
    color: 'white',
    border: '2px white solid',
    borderRadius: 20,
    height: 38,
    width: 350,
    marginTop: 20,
    marginBotom: 20,
    marginLeft: 50,
    fontSize: 14,
    fontFamily: "Nunito Sans Bold",
  },
  textField: {
    width: 30,
    marginLeft: 15,
    marginRight: 10,
    textAlign: "right",
    backgroundColor: 'white',
    "& $div": {
      background: 'red',
      "& :before": {
        border: 'none'
      }
    }
  },
  notes: {
    backgroundColor: "white",
    fontSize: 16,
    fontFamily: "Nunito Sans Bold",
    "& $div": {
      "& $div": {
        "& $fieldset": {
          border: 'none'
        }
      }
    }
  },
  fontNormal: {
    fontSize: 15,
    fontWeight: "normal"
  },
  activityContainer: {
    fontSize: 15,
    fontWeight: 700,
    paddingTop: 10
  },
  repostsSearchContainer: {
    fontSize: 15,
    fontWeight: 700,
    paddingTop: 10,
    marginLeft: 5
  },
  activityText: {
    width: 80,
    marginLeft: 10,
    backgroundColor: 'white'
  },
  forecastInput: {
    width: 200,
    backgroundColor: 'white',
    height: 49,
    lineHeight: 49
  },
  printButton: {
    float: 'right',
    marginLeft: '10px',
    width: 50,
    height: 50,
    minWidth: 50,
    marginTop: 35,
    backgroundColor: 'white',
    color: darkGrayText,
    display: 'inline-flex'
  },
  boldedLabel: {
    margin: 10,
    fontWeight: 500
  },
  navigationArrowLeft: {
    backgroundColor: 'white',
    color: '#5C6FA2',
    borderRadius: 20,
    position: 'absolute',
    right: 5,
    width: 25,
    height: 25
  },
  navigationArrowRight: {
    backgroundColor: 'white',
    color: '#5C6FA2',
    borderRadius: 20,
    position: 'absolute',
    left: 5,
    width: 25,
    height: 25
  },
  loader: {
    position: 'absolute',
    width: '93.7%',
    height: '350px',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: '1000',
    textAlign: 'center',
    paddingTop: '250px'
  },
  tableContainer: {
    maxHeight: 700,
    overflowY: 'auto'
  },
  floatRightWithMargin: {
    margin: 5,
    float: 'right'
  },
  optionContainer: {
    padding: "5px 20px",
    marginTop: "5px",
    width: "fit-content",
    backgroundColor: "lightblue",
    borderRadius: "5px",
    display: "block"
  },
  tableMobileFont: {
    fontSize: 12
  },
  tableFont: {
    fontSize: 16
  },
  adminFormDescription: {
    marginLeft: 5
  },
  dialog: {
    width: 1200,
    maxWidth: 1100,
    height: 400,
    padding: '0 20px',
    backgroundColor: 'rgb(231, 231, 232)'
  }
}
